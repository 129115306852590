import React from "react"
import "./colbaField.scss"

export const ColbaField = ({
    placeholder,
    label,
    value,
    onChange,
    name,
    className = "",
    type = "text",
}) => {
    return (
        <div className={`colbaField ${className}`}>
            <label htmlFor={name}>{label}</label>
            {type === "textarea" ? (
                <textarea
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            ) : (
                <input
                    name={name}
                    id={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        </div>
    )
}
