import { useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import "./careersJobBody.scss"

export const CareersJobBody = ({ content }) => {
    const intl = useIntl()
    return (
        <section className="careersJobBody">
            <div className="contained">
                <span className="introTitle">
                    {intl.formatMessage({ id: "CAREERS_JOB_BODY_INFO" })}
                </span>
                <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </section>
    )
}
