import React, { useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { ColbaField } from "../../../components/colbaField/colbaField"
import "./careersJobForm.scss"
export const CareersJobForm = ({ jobTitle }) => {
    const intl = useIntl()
    const [action, setAction] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        const act = `mailto:hello@colba.es?subject=${encodeURI(
            `${name} Apply for ${jobTitle}`
        )}&body=${encodeURI(description)}`
        setAction(act)
    }, [name, description])

    return (
        <section className="careersJobForm">
            <div className="form contained" action={action}>
                <h2>{intl.formatMessage({ id: "CAREERS_JOB_APPLY" })}</h2>
                <p>
                    {intl.formatMessage({
                        id: "CAREERS_JOB_APPLY_DESCRIPTION",
                    })}
                </p>
                <div className="fields">
                    <ColbaField
                        className="field full"
                        value={name}
                        onChange={({ target }) => setName(target.value)}
                        label={intl.formatMessage({
                            id: "CAREERS_JOB_FORM_NAME",
                        })}
                        placeholder={intl.formatMessage({
                            id: "CAREERS_JOB_FORM_NAME_PLACEHOLDER",
                        })}
                    />
                    <ColbaField
                        className="field full"
                        value={description}
                        onChange={({ target }) => setDescription(target.value)}
                        label={intl.formatMessage({
                            id: "CAREERS_JOB_FORM_DETAILS",
                        })}
                        placeholder={intl.formatMessage({
                            id: "CAREERS_JOB_FORM_DETAILS_PLACEHOLDER",
                        })}
                        type="textarea"
                    />
                    <a href={action} className="field submit">
                        {intl.formatMessage({
                            id: "CAREERS_JOB_FORM_APPLY",
                        })}
                    </a>
                </div>
            </div>
        </section>
    )
}
