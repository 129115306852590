import { Link } from "gatsby-plugin-react-intl"
import React from "react"
import "./careersHeader.scss"

export const CareersHeader = ({ title, subtitle, routePath, properties }) => {
    return (
        <section className="careerHeader">
            {!!routePath && (
                <ul className="routePath">
                    {routePath.map(({ link, label }, index) => (
                        <li key={link}>
                            <Link to={link}>{label}</Link>
                            {index !== routePath.length - 1 && (
                                <span>{">"}</span>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            {!!subtitle && <span className="subtitle">{subtitle}</span>}
            <h1>{title}</h1>
            {!!properties && (
                <ul className="properties">
                    {properties.map(({ icon, label }) => (
                        <li key={label}>
                            {icon}
                            <span>{label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    )
}
