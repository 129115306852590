import React, { useRef } from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

import { CareersJobBody } from "./careersJobBody/careersJobBody"
import { CareersJobForm } from "./careersJobForm/careersJobForm"
import { MainLayout } from "../../components/mainLayout/mainLayout"
import { CareersHeader } from "../../blocs/careersHeader/careersHeader"
import level from "../../assets/images/icons/level.svg"
import location from "../../assets/images/icons/location.svg"
import timing from "../../assets/images/icons/timing.svg"
import { SEO } from "../../components/seo/seo"

const JobPostTemplate = ({ data }) => {
    const intl = useIntl()
    const job = useRef(
        (() => {
            const node = data.allMarkdownRemark.edges.find(
                (edge) => edge.node.frontmatter.language === intl.locale
            ).node

            const { html, frontmatter } = node
            const { image, ...rest } = frontmatter
            return {
                content: html,
                image: `${data.site.siteMetadata.siteUrl}${image.publicURL}`,
                ...rest,
            }
        })()
    )

    return (
        <MainLayout>
            <SEO
                title="Careers"
                meta={[
                    {
                        name: "og:image",
                        content: job.current.image,
                    },
                ]}
            />
            <CareersHeader
                routePath={[
                    {
                        label: intl.formatMessage({ id: "LABEL_CAREERS" }),
                        link: "/careers/",
                    },
                    {
                        label: job.current.title,
                        link: `/careers/${job.current.slug}`,
                    },
                ]}
                title={job.current.title}
                properties={[
                    {
                        icon: <img src={location} />,
                        label: job.current.location,
                    },
                    { icon: <img src={level} />, label: job.current.level },
                    { icon: <img src={timing} />, label: job.current.timing },
                ]}
            />
            <CareersJobBody content={job.current.content} />
            <CareersJobForm jobTitle={job.current.title} />
        </MainLayout>
    )
}

export default JobPostTemplate

export const postQuery = graphql`
    query ($slug: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        allMarkdownRemark(filter: { frontmatter: { slug: { eq: $slug } } }) {
            edges {
                node {
                    frontmatter {
                        tags
                        level
                        timing
                        location
                        title
                        slug
                        language
                        image {
                            publicURL
                        }
                    }
                    html
                }
            }
        }
    }
`
